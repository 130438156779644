"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrder = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var IpisBrand_1 = require("../IpisBrand");
var CheckInOut_1 = require("./CheckInOut");
var WorkOrderCategory_1 = require("./WorkOrderCategory");
var WorkOrderContactInfo_1 = require("./WorkOrderContactInfo");
var WorkOrderLocation_1 = require("./WorkOrderLocation");
var WorkOrderStatus_1 = require("./WorkOrderStatus");
var WorkOrderInvoice_1 = require("./WorkOrderInvoice");
var WorkOrderHousingType_1 = require("./WorkOrderHousingType");
var WorkOrder;
(function (WorkOrder) {
    WorkOrder.WorkPeriodSchema = centralized_zod_1.z.object({
        checkIn: CheckInOut_1.CheckInOut.Schema,
        checkOut: CheckInOut_1.CheckInOut.Schema.nullable(),
    });
    WorkOrder.AllowedMaterialEnum = centralized_zod_1.z.enum([
        "searchAndCustom",
        "quickAddServiceContract",
        "quickAddTypeOfService",
    ]);
    /*
    Fields accessible without signing in as a handyman, as long as you have access to the GUID
    needed to query the fields
    */
    WorkOrder.SharedFieldsSchema = centralized_zod_1.z.object({
        orderId: centralized_zod_1.z.string(),
        opportunityId: centralized_zod_1.z.string(),
        caseId: centralized_zod_1.z.string().nullable(),
        serialNumber: centralized_zod_1.z.string(),
        customerId: centralized_zod_1.z.string(),
        description: centralized_zod_1.z.string(),
        additionalInformation: centralized_zod_1.z.string(),
        confirmedByCustomer: centralized_zod_1.z.boolean(),
        location: WorkOrderLocation_1.WorkOrderLocation.Schema,
        contact: WorkOrderContactInfo_1.WorkOrderContactInfo.Schema,
        actionDescription: centralized_zod_1.z.string(),
        paymentDetails: centralized_zod_1.z.object({
            externalPaymentFlowAllowed: centralized_zod_1.z.boolean(),
            externalPaymentFlowDeadline: centralized_zod_1.z.date().nullable(),
        }),
        customerDetails: centralized_zod_1.z.object({
            assumedInvoiceEmail: centralized_zod_1.z.string().nullable(),
            city: centralized_zod_1.z.string().nullable(),
            street: centralized_zod_1.z.string().nullable(),
            postalCode: centralized_zod_1.z.string().nullable(),
            gdprConfirmed: centralized_zod_1.z.boolean(),
        }),
        endCustomer: centralized_zod_1.z.object({
            name: centralized_zod_1.z.string().nullable(),
            phone: centralized_zod_1.z.string().nullable(),
            email: centralized_zod_1.z.string().nullable(),
        }),
        selectedInvoiceInformation: WorkOrderInvoice_1.WorkOrderInvoice.PartialSchema.nullable(),
        selectedHousingInformation: WorkOrderHousingType_1.WorkOrderHousingType.Schema.nullable(),
        startDate: centralized_zod_1.z.date(),
        endDate: centralized_zod_1.z.date(),
        category: WorkOrderCategory_1.WorkOrderCategory.Schema,
        apiStatus: WorkOrderStatus_1.WorkOrderStatus.Schema,
        typeOfService: centralized_zod_1.z.object({
            category: centralized_zod_1.z.enum(["electrical", "plumbing", "other"]),
            name: centralized_zod_1.z.string(),
        }),
        isFinished: centralized_zod_1.z.boolean(),
        isBusinessCustomer: centralized_zod_1.z.boolean(),
        requiresMaterialToBeReviewed: centralized_zod_1.z.boolean(),
        brand: IpisBrand_1.IpisBrand.Schema,
        coordinator: centralized_zod_1.z.object({
            id: centralized_zod_1.z.string(),
            firstName: centralized_zod_1.z.string().nullable(),
            lastName: centralized_zod_1.z.string().nullable(),
            email: centralized_zod_1.z.string().nullable(),
            phone: centralized_zod_1.z.string().nullable(),
        }),
        externalReference: centralized_zod_1.z.string().nullable(),
        isReadyToPay: centralized_zod_1.z.boolean(),
        discountDetails: centralized_zod_1.z.object({
            discountType: centralized_zod_1.z.enum(["rot", "green", "rut"]).nullable(),
            /*
              Better name please
            */
            customerHasActivelyChosenToSkipDiscount: centralized_zod_1.z.boolean(),
        }),
        externalCustomerPreparationForm: centralized_zod_1.z
            .object({
            id: centralized_zod_1.z.string(),
            name: centralized_zod_1.z.string(),
            completedDate: centralized_zod_1.z.date().nullable(),
        })
            .nullable(),
        /*
          These will only exist in the development environment
        */
        customerGuid: centralized_zod_1.z.string().nullable(),
        externalCustomerGuid: centralized_zod_1.z.string().nullable(),
    });
    WorkOrder.CustomerSchema = WorkOrder.SharedFieldsSchema.merge(centralized_zod_1.z.object({
        viewedBy: centralized_zod_1.z.literal("customer"),
        assignedTo: centralized_zod_1.z
            .object({
            handymanName: centralized_zod_1.z.string(),
        })
            .nullable(),
    }));
    WorkOrder.HandymanSchema = WorkOrder.SharedFieldsSchema.extend({
        viewedBy: centralized_zod_1.z.literal("handyman"),
        assignedTo: centralized_zod_1.z
            .object({
            handymanId: centralized_zod_1.z.string(),
            handymanName: centralized_zod_1.z.string(),
            companyId: centralized_zod_1.z.string(),
            companyName: centralized_zod_1.z.string(),
        })
            .optional(),
        /*
            Consider putting internalDescription in the coordinator object
          */
        internalDescription: centralized_zod_1.z.string().nullable(),
        importantInformation: centralized_zod_1.z.string().nullable(),
        checklist: centralized_zod_1.z
            .object({
            id: centralized_zod_1.z.string(),
            name: centralized_zod_1.z.string(),
        })
            .nullable(),
        internalActionNote: centralized_zod_1.z.string(),
        workerGuid: centralized_zod_1.z.string(),
        productPackageId: centralized_zod_1.z.string().nullable(),
        workPeriods: WorkOrder.WorkPeriodSchema.array(),
        serviceContract: centralized_zod_1.z
            .object({
            id: centralized_zod_1.z.string(),
            name: centralized_zod_1.z.string(),
            description: centralized_zod_1.z.string().nullable(),
            allowedMaterial: WorkOrder.AllowedMaterialEnum.array().nullable(),
        })
            .nullable(),
        defaultExtraHour: centralized_zod_1.z.string().nullable(),
        workOrderStatus: centralized_zod_1.z.object({
            unsuccessfulOptions: centralized_zod_1.z.string().array(),
            unsuccessfulType: centralized_zod_1.z.enum(["failed", "partial"]).nullable(),
            unsuccessfulComment: centralized_zod_1.z.string().nullable(),
            unsuccessfulReason: centralized_zod_1.z.string().nullable(),
        }),
        priceBooks: centralized_zod_1.z.object({
            defaultId: centralized_zod_1.z.string().nullable(),
            endCustomerId: centralized_zod_1.z.string().nullable(),
        }),
    });
    /*
      This shouldn't exist. We could create some sort of union object, that combines
      a work order, a handyman and the permissions. But adding the permission fields
      directly to the work order object doesn't make a lot of sense (it's not actually part of the work order)
  
      I don't feel this is a priority to fix however, and it's not a very big deal,
      so we'll leave it like this for now.
    */
    WorkOrder.HandymanWithPermissionsSchema = WorkOrder.HandymanSchema.merge(centralized_zod_1.z.object({
        allowedToHandleOrder: centralized_zod_1.z.boolean(),
        isAssignedThisOrder: centralized_zod_1.z.boolean(),
        lineItemPermissions: centralized_zod_1.z.object({
            canUseQuickAdd: centralized_zod_1.z.boolean(),
            canUseFreeSearchAndCustomMaterial: centralized_zod_1.z.boolean(),
            canUseQuickAddFromServiceContract: centralized_zod_1.z.boolean(),
            canUseQuickAddFromTypeOfService: centralized_zod_1.z.boolean(),
        }),
    }));
    WorkOrder.Schema = centralized_zod_1.z.discriminatedUnion("viewedBy", [
        WorkOrder.CustomerSchema,
        //HandymanSchema,
        WorkOrder.HandymanWithPermissionsSchema,
    ]);
})(WorkOrder || (WorkOrder = {}));
exports.WorkOrder = WorkOrder;
