import { PaymentOption, WorkOrderHousingType } from "@eljouren/domain";
import { WorkOrderInvoice } from "@eljouren/domain/";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import {
  IpisButton,
  IpisSelectCompact,
  IpisTextArea,
  IpisTextInputCompact,
  IpisToggleInput,
} from "@ipis/client-essentials";
import { useContext, useState } from "react";
import {
  DeepPartial,
  FieldErrors,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import RotAvdragImportantInformationSectionV2 from "../../../../../components/__v2__/RotAvdragImportantInformationSectionV2";
import GDPRLinkAndConfirmationCheckboxV2 from "../../../../../components/__v2__/form-components/GDPRConfirmationCheckboxV2";
import ResursbankLogo from "../../../../../components/external-brands/resursbank/ResursbankLogo";
import TrustlyLogo from "../../../../../components/external-brands/trustly/TrustlyLogo";
import ClientUtils from "../../../../../utils/ClientUtils";
import PaymentMethodUtils from "../../../work-order-payment/payment-method-components/PaymentMethodUtils";
import { CustomerWorkOrderFormContext } from "../CustomerWorkOrderFormSectionV2";

export const CustomerWorkOrderFormInvoicePageFormSchema = z.object({
  isInsuranceCase: z.boolean(),
  customerActivelyChoseToSkipTaxReduction: z.boolean(),
  housing: WorkOrderHousingType.Schema,
  invoice: WorkOrderInvoice.Schema.optional(),
  gdprConfirmed: z.literal(true),
});
export type CustomerWorkOrderFormInvoicePageFormValues = z.infer<
  typeof CustomerWorkOrderFormInvoicePageFormSchema
>;

interface Props {
  className?: string;
  defaultValues?: DeepPartial<CustomerWorkOrderFormInvoicePageFormValues>;
  onSubmit?: (values: CustomerWorkOrderFormInvoicePageFormValues) => void;
  onGoBack?: (
    values: DeepPartial<CustomerWorkOrderFormInvoicePageFormValues>
  ) => void;
  isSubmitting?: boolean;
  hasSubmitError?: boolean;
  resetSubmitError?: () => void;

  /* 
    I don't know what this is
  */
  defaultInvoiceMail?: string;
}

type ShowErrors =
  | false
  | FieldErrors<CustomerWorkOrderFormInvoicePageFormValues>;

const CustomerWorkOrderFormInvoicePage = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderFormContext);
  const wo = ctx.workOrder;

  const form = useForm<CustomerWorkOrderFormInvoicePageFormValues>({
    defaultValues: getDefaultValues(),
    /* 
      Will need to validate this based on if the customer is a company, it's an insurance case, and if they've actively chosen to skip the tax reduction.

      We need to figure out if we can do this validation in the parent component or if it needs to happen here
    */
    resolver: zodResolver(CustomerWorkOrderFormInvoicePageFormSchema),
  });

  const [showErrors, setShowErrors] = useState<ShowErrors>(false);

  function getDefaultValues(): DeepPartial<CustomerWorkOrderFormInvoicePageFormValues> {
    return props.defaultValues ?? {};
  }

  function onSubmit(values: CustomerWorkOrderFormInvoicePageFormValues) {
    props.onSubmit?.(values);
  }

  function onError(
    errors: FieldErrors<CustomerWorkOrderFormInvoicePageFormValues>
  ) {
    setShowErrors(errors);
  }

  const showTaxReductionSection =
    !form.watch("isInsuranceCase") && !wo.isBusinessCustomer;

  const showHousingInputs =
    showTaxReductionSection &&
    !form.watch("customerActivelyChoseToSkipTaxReduction");

  return (
    <form
      className={ClientUtils.classNames(
        "flex h-full flex-col gap-8",
        props.isSubmitting && "pointer-events-none opacity-50"
      )}
      onSubmit={form.handleSubmit(onSubmit, onError)}
    >
      {/* 
        Insurance case
      */}
      <section className="flex flex-col gap-4">
        <h2 className="text-xl">Försäkringsärende</h2>
        <IpisToggleInput
          id="isInsuranceCase"
          label="Är arbetsordern ett försäkringsärende?"
          form={form}
          name="isInsuranceCase"
          required
          /* 
            This is stupid
          */
          richTextNote={`<p>Om arbetsordern är ett försäkringsärende behöver du inte fylla in information angående ROT och avdrag för grön teknik, eftersom att dessa avdrag <strong>inte gäller för försäkringsärenden.</strong></p>`}
        />
      </section>

      {/* 
          Rot and green tech deduction
      */}
      {showTaxReductionSection && (
        <section className="flex flex-col gap-8">
          <IpisToggleInput
            required
            id="customerActivelyChoseToSkipTaxReduction"
            label="Jag vill inte använda mig av ROT-avdrag eller avdrag för grön teknik"
            form={form}
            name="customerActivelyChoseToSkipTaxReduction"
          />
          <RotAvdragImportantInformationSectionV2
            housingType={wo.selectedHousingInformation?.housingType}
          />
          {showHousingInputs && (
            <HousingInputs form={form} showErrors={showErrors} />
          )}
        </section>
      )}
      {/* 
        Payment section
      */}
      {wo.paymentDetails.externalPaymentFlowAllowed && (
        <ResursBankPaymentSection />
      )}
      {!wo.paymentDetails.externalPaymentFlowAllowed && (
        <InvoiceMethodSection form={form} showErrors={showErrors} />
      )}

      <footer className="flex flex-col gap-2 pt-4">
        <GDPRLinkAndConfirmationCheckboxV2
          form={form}
          name="gdprConfirmed"
          useCase="workOrderConfirmationForm"
        />

        {props.onSubmit && <IpisButton type="submit" label="Slutför" />}
        {props.onGoBack && (
          <IpisButton
            variant="text"
            label="Gå tillbaka"
            onClick={() => props.onGoBack!(form.getValues())}
          />
        )}
      </footer>
    </form>
  );
};

const HousingInputs = (props: {
  form: UseFormReturn<CustomerWorkOrderFormInvoicePageFormValues>;
  showErrors: ShowErrors;
}) => {
  const form = props.form;
  const showErrors = props.showErrors;
  const housingType = form.watch("housing.housingType");

  return (
    <section
      className={ClientUtils.classNames(
        "flex flex-col gap-4 border-2 border-transparent",
        housingType === "insuranceMatter" && "pointer-events-none opacity-50",
        showErrors && showErrors.housing && "focus-within:border-orange-300"
      )}
    >
      <IpisSelectCompact
        id="housingTypeSelect"
        required
        form={form}
        name="housing.housingType"
        label="Välj bostadstyp"
        options={[
          {
            value: "house",
            label: "Villa",
          },
          {
            value: "condomonium",
            label: "Bostadsrätt",
          },
          {
            value: "rentalApartment",
            label: "Hyreslägenhet",
          },
        ]}
      />

      {(housingType === "house" || housingType === "condomonium") && (
        <fieldset key={housingType} className="flex flex-col gap-4 rounded">
          {housingType === "house" && (
            <IpisTextInputCompact
              required={false}
              id="propertyDesignationInput"
              form={form}
              label="Fastighetsbeteckning"
              name="housing.propertyDesignation"
            />
          )}
          {housingType === "condomonium" && (
            <>
              <IpisTextInputCompact
                required={false}
                id="housingCooperativeOrgNoInput"
                form={form}
                label="Organisationsnummer"
                name="housing.housingCooperativeOrgNo"
              />
              <IpisTextInputCompact
                required={false}
                id="apartmentNumberInput"
                form={form}
                label="Lägenhetsnummer"
                name="housing.apartmentNumber"
              />
            </>
          )}
        </fieldset>
      )}
    </section>
  );
};

const ResursBankPaymentSection = () => {
  /* 
      Show info about ResursBank
    */

  const trustly = PaymentMethodUtils.getHeadings({
    brand: PaymentOption.BrandEnum.trustly,
    type: PaymentOption.NativeEnum.directPayment,
    name: "Trustly",
  });

  const resursInvoice = PaymentMethodUtils.getHeadings({
    brand: PaymentOption.BrandEnum.resursbank,
    type: PaymentOption.NativeEnum.invoice,
    name: "Resursbank Faktura",
  });

  const resursPartialPayment = PaymentMethodUtils.getHeadings({
    brand: PaymentOption.BrandEnum.resursbank,
    type: PaymentOption.NativeEnum.partialPayment,
    name: "Delbetalning",
    variant: "12_MONTHS",
  });

  return (
    <section className="flex flex-col gap-4">
      <header>
        <h2 className="text-lg text-dark-950">Betalningsalternativ</h2>
        <p>
          I samarbete med Resurs Bank erbjuder vi dig möjligheten att själv
          välja hur du vill betala. När din arbetsorder är slutförd så kommer du
          att få ett SMS med en länk där du kan se en sammanställning av din
          arbetsorder och genomgå betalningen.
        </p>
      </header>
      <ul className="flex flex-col gap-2">
        <li className="flex items-center justify-between rounded bg-input-background p-2">
          <span>{trustly.heading}</span>
          <TrustlyLogo height={30} />
        </li>
        <li className="flex items-center justify-between rounded bg-input-background p-2">
          <span>{resursInvoice.heading}</span>
          <ResursbankLogo height={30} />
        </li>
        <li className="flex items-center justify-between rounded bg-input-background p-2">
          <span>{resursPartialPayment.heading}</span>
          <ResursbankLogo height={30} />
        </li>
      </ul>
    </section>
  );
};

const InvoiceMethodSection = (props: {
  form: UseFormReturn<CustomerWorkOrderFormInvoicePageFormValues>;
  showErrors: ShowErrors;
}) => {
  const ctx = useContext(CustomerWorkOrderFormContext);
  const wo = ctx.workOrder;
  const invoiceMethod = props.form.watch("invoice.invoiceMethod");

  /* 
    Stupid workaround to the textfields being coupled to a form
  */
  const localForm = useForm<{
    invoice: {
      street: string;
      postalCode: string;
      city: string;
    };
  }>({
    defaultValues: {
      invoice: {
        street: wo.customerDetails?.street ?? "",
        postalCode: wo.customerDetails?.postalCode ?? "",
        city: wo.customerDetails?.city ?? "",
      },
    },
  });

  return (
    <section
      className={ClientUtils.twClassNames(
        "flex flex-col gap-4 border-2 border-transparent",
        props.showErrors &&
          props.showErrors.housing &&
          "focus-within:border-error"
      )}
    >
      <header>
        <h2 className="text-xl">Faktureringsinformation</h2>
      </header>
      <main className="flex flex-col gap-4 pb-2">
        <IpisSelectCompact
          required
          id="invoiceMethodSelect"
          form={props.form}
          name="invoice.invoiceMethod"
          label="Välj faktureringsmetod"
          options={[
            {
              value: "email",
              label: "E-post",
            },
            {
              value: "postal",
              label: "Pappersfaktura (avgift 50:-)",
            },
          ]}
        />

        <fieldset className="flex flex-col gap-2 rounded">
          {invoiceMethod === "email" && (
            <IpisTextInputCompact
              required
              id="invoiceEmailInput"
              form={props.form}
              name="invoice.email"
              label="E-post för faktura"
              type="email"
            />
          )}
          {invoiceMethod === "postal" && (
            <>
              <IpisTextInputCompact
                required
                id="invoiceStreetInput"
                form={localForm}
                name="invoice.street"
                label="Gata"
                readOnly
                /*  htmlAttributes={{
                    disabled: true,
                    readOnly: true,
                    value:
                      workOrderCtx.workOrder.customerDetails?.street ??
                      undefined,
                    name: "invoice.street",
                  }} */
              />
              <IpisTextInputCompact
                required
                id="invoicePostalCodeInput"
                form={localForm}
                name="invoice.postalCode"
                label="Postnummer"
                readOnly
                /* htmlAttributes={{
                    disabled: true,
                    readOnly: true,
                    name: "invoice.postalCode",
                    value:
                      workOrderCtx.workOrder.customerDetails?.postalCode ??
                      undefined,
                  }} */
              />
              <IpisTextInputCompact
                required
                id="invoiceCityInput"
                form={localForm}
                name="invoice.city"
                label="Stad"
                readOnly
                /*   htmlAttributes={{
                    disabled: true,
                    readOnly: true,
                    name: "invoice.city",
                    value:
                      workOrderCtx.workOrder.customerDetails?.city ?? undefined,
                  }} */
              />
            </>
          )}
        </fieldset>
        <IpisTextArea
          required={false}
          id="additionalInformationTextArea"
          form={props.form}
          name="invoice.additionalInformation"
          label="Ytterligare faktureringsinformation"
          rows={3}
          maxLength={1000}
          placeholder="Finns det något mer vi behöver veta?"
        />
      </main>
    </section>
  );
};

export default CustomerWorkOrderFormInvoicePage;
